<template>
  <div class="event-root-container">
    <img :src="icons.ysLogo" style="height: 35px" />
    <div class="event-main-container">
      <span style="font-size: 13px; font-weight: bold"
        >您委托 游用工 的付款 #{{ dataSource.queue_id }}# 已完成</span
      >
      <span style="color: 909090; font-size: 12px; margin-top: 5px">{{
        createTimeFormat
      }}</span>
    </div>
    <div class="event-right-container">
      <fee
        :amount="dataSource.salary_amount"
        :primarySize="12"
        :secondarySize="12"
        feeType="out"
      />
      <span style="color: 909090; font-size: 12px; margin-top: 5px"
        >共计{{ dataSource.count }}笔</span
      >
    </div>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  computed: {
    createTimeFormat() {
      return moment(this.dataSource.create_time).format("YYYY/MM/DD HH:mm");
    },
  },
};
</script>

<style scoped>
.event-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  margin: 10px;
  background-color: #fff;
  transition: all 0.2s ease;
  padding: 20px 30px;
  box-shadow: 0px 0px 1px #dddddd;
}
.event-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.event-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.event-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 30px;
}
</style>