<template>
  <div class="event-root-container">
    <div
      class="event-row-container"
      style="cursor: pointer"
      @click.stop="goDetail(dataSource.new.queue_id)"
    >
      <img :src="icons.ysLogo" style="height: 35px" />
      <div class="event-main-container">
        <span style="font-size: 13px; font-weight: bold"
          >重新发送队列 #{{ dataSource.new.name }}# 已完成</span
        >
      </div>
      <div class="event-right-container">
        <fee
          :amount="dataSource.new.salary_amount"
          :primarySize="12"
          :secondarySize="12"
          feeType="out"
        />
        <span
          style="
            color: #2b85e4;
            font-size: 12px;
            margin-top: 5px;
            cursor: pointer;
          "
          @click.stop="compare"
          >对比</span
        >
      </div>
    </div>
    <div
      class="event-item-container"
      style="cursor: pointer"
      @click.stop="goDetail(dataSource.old.queue_id)"
    >
      <span style="font-size: 12px"
        >旧处理队列#{{ dataSource.old.name }}#
      </span>
      <fee
        :amount="dataSource.old.salary_amount"
        :primarySize="12"
        :secondarySize="12"
      />
    </div>
  </div>
</template>

<script>
import Fee from "../../../components/Fee";
import MyAvatar from "../../../components/MyAvatar";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  computed: {
    createTimeFormat() {
      return moment(this.dataSource.create_time).format("YYYY/MM/DD HH:mm");
    },
  },
  methods: {
    goDetail(id) {
      this.$router.push({
        name: "queue-detail",
        params: {
          id: id,
        },
      });
    },
    compare() {
      this.$emit("compare", this.dataSource.error_id);
    },
  },
};
</script>

<style scoped>
.event-root-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  transition: all 0.2s ease;
  padding: 20px 30px 20px 40px;
  box-shadow: 0px 0px 1px #dddddd;
}
.event-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.event-row-container {
  display: flex;
  flex-direction: row;
}
.event-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}
.event-right-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-left: 30px;
}
.event-item-container {
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  background-color: #f3f3f3;
}
</style>