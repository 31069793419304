<template>
  <div class="task-tip-root-container">
    <div
      class="task-tip-top-container"
      :style="{ 'background-image': 'url(' + icons.taskBanner + ')' }"
    >
      <span style="font-weight: bold">任务完成确认</span>
    </div>
    <div class="task-tip-main-container">
      <Icon :size="100" color="#ddd" type="ios-paper-outline" />
      <span style="margin: 20px 0">没有需要确认的已完成任务😊</span>
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    goCompany() {
      this.$router.push({
        name: "employer-home",
      });
    },
  },
};
</script>

<style scoped>
.task-tip-root-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  padding: 10px 0;
  background-color: #fff;
  overflow: hidden;
}
.task-tip-top-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 5px;
  width: 100%;
  height: 125px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.task-tip-main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>