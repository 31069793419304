<template>
  <div class="event-root-container">
    <my-avatar
      :size="35"
      :name="dataSource.name"
      :img_url="dataSource.img_url"
    ></my-avatar>
    <div class="event-main-container">
      <span style="font-size: 13px; font-weight: bold"
        >成员 {{ dataSource.name }} 发布了项目 {{ dataSource.project_name }}</span
      >
      <span style="color: 909090; font-size: 12px; margin-top: 5px">{{
        createTimeFormat
      }}</span>
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
  },
  components:{
    "my-avatar":MyAvatar
  },
  computed: {
    createTimeFormat() {
      return moment(this.dataSource.create_time).format("YYYY/MM/DD HH:mm");
    },
  },
};
</script>

<style scoped>
.event-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  transition: all 0.2s ease;
  padding: 20px 40px;
  box-shadow: 0px 0px 1px #dddddd;
}
.event-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.event-main-container {
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
</style>