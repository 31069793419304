<template>
  <div class="queue-tip-root-container">
    <img :src="icons.homeQueue" style="height: 40px; margin: 0px 10px" />
    <div class="queue-tip-main-container">
      <span
        style="
          margin-bottom: 10px;
          color: #fff;
          font-size: 15px;
          font-weight: bold;
        "
        >有{{ count }}个付款计划等待批准</span
      >
      <my-input
        size="large"
        placeholder="8位密码"
        ref="formCode"
        type="password"
        title=""
        v-model="code"
        :validate="codeValidate"
      />

      <div class="queue-btn" @click="create">
        <Icon
          type="ios-card"
          size="18"
          color="#3385A6"
          style="margin-right: 5px"
        />
        <span>完成付款出账</span>
      </div>
    </div>

    <create-queue-modal ref="createQueueModal" @close="goQueue" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../../common/icon";
import MyInput from "../../../components/Form/MyInput";
import CreateQueueModal from "../../Queue/Modal/CreateQueueNewModal";
export default {
  props: {
    count: Number,
  },
  components: {
    "my-input": MyInput,
    "create-queue-modal": CreateQueueModal,
  },
  data() {
    return {
      icons: icon,

      code: "",
    };
  },
  methods: {
    ...mapActions({
      queueCheckPwdAction: "queueCheckPwd",
    }),
    create() {
      Promise.all([this.$refs.formCode.doValidate()])
        .then(() => {
          this.queueCheckPwdAction({
            employer_id: localStorage.getItem("employer_id"),
            pwd: this.code,
          })
            .then((res) => {
              localStorage.setItem("code", this.code);
              this.$refs.createQueueModal.open();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    goQueue() {
      this.$router.push({
        name: "queue",
      });
    },
    /** 表单验证 */
    codeValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.code == "") {
          reject("请填写8位数字密码");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.queue-tip-root-container {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  padding: 20px 10px;
  background-color: #2f72bb;
  overflow: hidden;
}
.queue-tip-main-container {
  display: flex;
  flex-direction: column;
}
.queue-btn {
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-radius: 10px;
  background-color: #e3eafa;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}
</style>