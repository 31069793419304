<template>
  <div class="enough-item-root-container">
    <div class="enough-item-row" style="margin-bottom: 10px">
      <span style="font-size:12px;">⚠️ 提醒：您在 游隼企业管理(山东)有限公司 的预付款额度不足以支付#{{dataSource.name}}#的付款交易</span>
      <div class="enough-item-row">不足金额:<fee :amount="dataSource.amount" :primarySize="12" :secondarySize="12" /></div>
    </div>
    <queue-item-card :data-source="card" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Fee from "../../../components/Fee";
import QueueItemCard from "../../Queue/Item/QueueItemCard";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "queue-item-card": QueueItemCard,
  },
  data() {
    return {
      card: {
        name: "",
        company_name:"",
        issuer: "",
        img_url: null,
        frozen_amount: 0,
        salary_amount: 0,
        service_amount: 0,
      },
    };
  },
  mounted() {
    this.assetGetCardDetailAction({
      employer_card_id: this.dataSource.employer_card_id,
      company_id:this.dataSource.company_id
    })
      .then((res) => {
        this.card = res;
      })
      .catch((error) => {
        this.$Message.error(error);
      });
  },
  methods: {
    ...mapActions({
      assetGetCardDetailAction: "assetGetCardDetail",
    }),
  },
};
</script>

<style scoped>
.enough-item-root-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 10px 20px;
  background-color: #fff;
}
.enough-item-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>