<template>
  <div class="event-root-container">
    <div class="event-row-container">
      <my-avatar
        :size="35"
        :name="dataSource.name"
        :img_url="dataSource.img_url"
      ></my-avatar>
      <div class="event-main-container">
        <span style="font-size: 13px; font-weight: bold"
          >成员{{ dataSource.name }}提交了付款计划</span
        >
        <span style="color: 909090; font-size: 12px; margin-top: 5px">{{
          createTimeFormat
        }}</span>
      </div>
      <div class="event-right-container">
        <fee :amount="totalAmount" :primarySize="12" :secondarySize="12" />
        <span style="color: 909090; font-size: 12px; margin-top: 5px"
          >合计</span
        >
      </div>
    </div>
    <div
      class="event-item-container"
      v-for="item in dataSource.pay_plan_detail_arr"
      :key="item.pay_plan_id"
    >
      <span style="font-size: 12px">#{{ item.pay_plan_id }}# </span>
      <fee
        :amount="item.amount"
        :primarySize="12"
        :secondarySize="12"
      />
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
import Fee from "../../../components/Fee";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  computed: {
    createTimeFormat() {
      return moment(this.dataSource.create_time).format("YYYY/MM/DD HH:mm");
    },
    totalAmount() {
      let result = 0;
      for (let item of this.dataSource.pay_plan_detail_arr) {
        result += item.amount;
      }
      return result;
    },
  },
};
</script>

<style scoped>
.event-root-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  transition: all 0.2s ease;
  padding: 20px 30px 20px 40px;
  box-shadow: 0px 0px 1px #dddddd;
}
.event-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.event-row-container {
  display: flex;
  flex-direction: row;
}
.event-right-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.event-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.event-item-container {
  margin-left: 60px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 15px;
  background-color: #f3f3f3;
}
</style>