<template>
  <Modal v-model="isShowModal" footer-hide fullscreen :transfer="true">
    <div class="queue-compare-modal-root-container">
      <div class="queue-compare-title-container">
        <div class="queue-compare-title">旧的</div>
        <div class="queue-compare-title"></div>
        <div class="queue-compare-title">新的</div>
      </div>
      <div class="queue-compare-list-container">
        <div class="queue-compare-list-item-container">
          <div
            class="queue-compare-item-container"
            v-for="(item, index) in error_list"
            :key="'queue-compare-error-' + index"
          >
            <div class="queue-compare-item-col">
              <div>姓名</div>
              <div>电话</div>
              <div>身份证号</div>
              <div>位置</div>
              <div>银行名称</div>
              <div>银行卡号</div>
              <div>开户行</div>
              <div>金额</div>
            </div>
            <div class="queue-compare-item-col" style="text-align: right">
              <div>{{ item.client_name }}</div>
              <div>{{ item.client_phone }}</div>
              <div>{{ item.client_idnum }}</div>
              <div>{{ item.position }}</div>
              <div>{{ item.bank_name }}</div>
              <div>{{ item.bank_num }}</div>
              <div>{{ item.bank_location }}</div>
              <div>{{ item.amount }}</div>
            </div>
          </div>
        </div>
        <div class="queue-compare-list-item-container"></div>
        <div class="queue-compare-list-item-container">
          <div
            class="queue-compare-item-container"
            v-for="(item, index) in new_list"
            :key="'queue-compare-new-' + index"
          >
            <div class="queue-compare-item-col">
              <div>姓名</div>
              <div>电话</div>
              <div>身份证号</div>
              <div>位置</div>
              <div>银行名称</div>
              <div>银行卡号</div>
              <div>开户行</div>
              <div>金额</div>
            </div>
            <div class="queue-compare-item-col" style="text-align: right">
              <div>{{ item.client_name }}</div>
              <div>{{ item.client_phone }}</div>
              <div>{{ item.client_idnum }}</div>
              <div>{{ item.position }}</div>
              <div>{{ item.bank_name }}</div>
              <div>{{ item.bank_num }}</div>
              <div>{{ item.bank_location }}</div>
              <div>{{ item.amount }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import { mapActions } from "vuex";
export default {
  data() {
    return {
      isShowModal: false,

      error_list: [],
      new_list: [],
    };
  },
  methods: {
    ...mapActions({
      queueGetErrorDetailAction: "queueGetErrorDetail",
    }),
    open(error_id) {
      this.new_list = [];
      this.error_list = [];
      this.isShowModal = true;

      this.queueGetErrorDetailAction({
        error_id: error_id,
      })
        .then((res) => {
          let error_info = res.error_info;
          let new_info = res.new_info;

          for (let i = 0; i < error_info.length; i++) {
            let error_obj = error_info[i];
            let new_obj = new_info[i];

            if (JSON.stringify(error_obj) != JSON.stringify(new_obj)) {
              this.error_list.push(error_obj);
              this.new_list.push(new_obj);
            }
          }
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style scoped>
.queue-compare-modal-root-container {
  display: flex;
  flex-direction: column;
  padding: 0px 100px;
}
.queue-compare-title-container {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
}
.queue-compare-title {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.queue-compare-list-container {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}
.queue-compare-list-item-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.queue-compare-item-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 25px;
  padding: 20px;
  box-shadow: 0px 0px 2px #dddddd;
}
.queue-compare-item-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.queue-compare-item-row {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
.queue-compare-item-col {
  display: flex;
  flex-direction: column;
}
.queue-compare-item-col > div {
  margin-bottom: 10px;
}
</style>