<template>
  <div class="event-root-container">
    <div class="event-row-container">
      <img :src="icons.ysLogo" style="height: 35px" />
      <div class="event-main-container">
        <span style="font-size: 13px; font-weight: bold"
          >您获得了 游用工 提供的 未使用的预付款额度</span
        >
        <span style="color: 909090; font-size: 12px; margin-top: 5px">{{
          createTimeFormat
        }}</span>
      </div>
      <fee
        :amount="dataSource.salary_amount"
        :primarySize="12"
        :secondarySize="12"
        feeType="in"
      />
    </div>
    <div class="event-item-container">
      <span style="font-size: 12px"
        >🎉 备注：扣除了 代扣代缴服务费
        <fee
          style="display:inline-block"
          :amount="dataSource.service_amount"
          :primarySize="12"
          :secondarySize="12"
          feeType="out"
      /></span>
    </div>
  </div>
</template>

<script>
import MyAvatar from "../../../components/MyAvatar";
import Fee from "../../../components/Fee";
import moment from "moment";
export default {
  props: {
    dataSource: Object,
  },
  data() {
    return {
      icons: this.$root.icons,
    };
  },
  components: {
    fee: Fee,
    "my-avatar": MyAvatar,
  },
  computed: {
    createTimeFormat() {
      return moment(this.dataSource.create_time).format("YYYY/MM/DD HH:mm");
    },
  },
};
</script>

<style scoped>
.event-root-container {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  margin: 10px;
  transition: all 0.2s ease;
  padding: 20px 30px;
  box-shadow: 0px 0px 1px #dddddd;
}
.event-root-container:hover {
  box-shadow: 0px 0px 10px #dddddd;
  transform: translateY(-2px);
}
.event-row-container {
  display: flex;
  flex-direction: row;
}
.event-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 30px;
}
.event-item-container {
  display: flex;
  border-radius: 10px;
  padding: 10px 20px;
  margin: 10px 0px 0px 80px;
  background-color: #f3f3f3;
}
</style>