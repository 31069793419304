<template>
  <div class="tip-root-container">
    <img
      :src="icons.tip2"
      style="width: 170px; margin: 0px 20px"
    />
    <div class="tip-main-container">
      <span style="font-size: 23px; font-weight: bold"
        >关键事务二：创建您的第一个项目</span
      >
      <span style="margin-top: 10px; font-size: 13px; color: #767676"
        >项目是与工作者建立联系的方式，您可以邀请或等待对方的要约，创建多种任务，组建团队，并在工作成果100%满意时向对方付款👍（要想成功完成对劳动者的付款，您至少要有一个项目）</span
      >
      <span
        style="color: #407fbf; margin-top: 20px; cursor: pointer"
        @click="goProject"
        >去创建</span
      >
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    goProject() {
      this.$router.push({
        name: "create-project",
      });
    },
  },
};
</script>

<style scoped>
.tip-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 20px 30px;
  background-color: #fff;
}
.tip-main-container {
  display: flex;
  flex-direction: column;
}
</style>