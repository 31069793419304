<template>
  <div class="tip-root-container">
    <img
      :src="icons.tip1"
      style="min-width: 170px; margin: 0px 20px"
    />
    <div class="tip-main-container">
      <span style="font-size: 23px; font-weight: bold"
        >关键事务一：至少添加一个关联企业</span
      >
      <span style="margin-top: 10px; font-size: 13px; color: #767676"
        >右上方的<Icon
          type="ios-home-outline"
        />可以完善您的信息，其中最重要的是添加一个用工实体企业并进行认证，完善银行卡、联系人、地址等信息。在后续的业务中，这些信息将被作为已知选项使用。</span
      >
      <span
        style="color: #407fbf; margin-top: 20px; cursor: pointer"
        @click="goCompany"
        >去添加</span
      >
    </div>
  </div>
</template>

<script>
import * as icon from "../../../common/icon";
export default {
  data() {
    return {
      icons: icon,
    };
  },
  methods: {
    goCompany() {
      this.$router.push({
        name: "employer-home",
      });
    },
  },
};
</script>

<style scoped>
.tip-root-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  padding: 20px 30px;
  background-color: #fff;
}
.tip-main-container {
  display: flex;
  flex-direction: column;
}
</style>