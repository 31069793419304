<template>
  <div class="dashboard-root-container">
    <div class="page-title">工作台</div>

    <div class="dashboard-main-container">
      <div class="dashboard-left-container">
        <tip1 style="margin-top: 20px" v-if="companyList.length == 0" />
        <tip2 style="margin-top: 20px" v-if="projectList.length == 0" />

        <div class="dashboard-notenough-container" v-if="queueList.length > 0">
          <notenough-item
            v-for="item in queueList"
            :key="item.queue_id"
            :dataSource="item"
          />
        </div>

        <span style="font-size: 23px; font-weight: bold; margin-top: 20px"
          >全部事件</span
        >

        <div v-for="item in eventList" :key="item.log_id">
          <create-project-event
            v-if="item.type == 1"
            :dataSource="item.content"
          />
          <got-it-event v-if="item.type == 2" :dataSource="item.content" />
          <create-company-event
            v-if="item.type == 3"
            :dataSource="item.content"
          />
          <complete-queue-event
            v-if="item.type == 4"
            :dataSource="item.content"
          />
          <create-plan-event v-if="item.type == 5" :dataSource="item.content" />
          <send-plan-event v-if="item.type == 6" :dataSource="item.content" />
          <resend-queue-event
            v-if="item.type == 7"
            :dataSource="item.content"
            @compare="compare"
          />
        </div>
      </div>
      <div class="dashboard-right-container">
        <task-tip style="margin-top: 20px" />
        <queue-tip
          v-if="planList.length != 0"
          :count="planList.length"
          style="margin-top: 20px"
        />
      </div>
    </div>

    <queue-compare-modal ref="queueCompareModal" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Tip1 from "./Panel/Tip1";
import Tip2 from "./Panel/Tip2";
import TaskTip from "./Panel/TaskTip";
import QueueTip from "./Panel/QueueTip";
import NotEnoughItem from "./Item/NotEnoughItem";
import CreateProjectEvent from "./Item/CreateProjectEvent";
import GotItEvent from "./Item/GotItEvent";
import CreateCompanyEvent from "./Item/CreateCompanyEvent";
import CompleteQueueEvent from "./Item/CompleteQueueEvent";
import CreatePlanEvent from "./Item/CreatePlanEvent";
import SendPlanEvent from "./Item/SendPlanEvent";
import ReSendQueueEvent from "./Item/ReSendQueueEvent";
import QueueCompareModalVue from "./Modal/QueueCompareModal.vue";
export default {
  components: {
    tip1: Tip1,
    tip2: Tip2,
    "task-tip": TaskTip,
    "queue-tip": QueueTip,
    "notenough-item": NotEnoughItem,
    "create-project-event": CreateProjectEvent,
    "got-it-event": GotItEvent,
    "create-company-event": CreateCompanyEvent,
    "complete-queue-event": CompleteQueueEvent,
    "create-plan-event": CreatePlanEvent,
    "send-plan-event": SendPlanEvent,
    "resend-queue-event": ReSendQueueEvent,
    "queue-compare-modal": QueueCompareModalVue,
  },
  data() {
    return {
      companyList: [],
      projectList: [],
      planList: [],
      queueList: [],
      eventList: [],
    };
  },
  mounted() {
    this.getCompanyList();
    this.getProjectList();
    this.getPlanList();
    this.getQueueList();
    this.getLog();
  },
  methods: {
    ...mapActions({
      getCompanyListAction: "getCompanyList",
      projectGetListAction: "projectGetList",
      planGetListAction: "planGetList",
      queueGetTempSaveListAction: "queueGetTempSaveList",
      logGetListAction: "logGetList",
    }),
    getCompanyList() {
      this.getCompanyListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          this.companyList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getProjectList() {
      this.projectGetListAction({
        employer_id: localStorage.getItem("employer_id"),
      }).then((res) => {
        this.projectList = res;
      });
    },
    getQueueList() {
      this.queueGetTempSaveListAction({
        employer_id: localStorage.getItem("employer_id"),
      }).then((res) => {
        this.queueList = res;
      });
    },
    getPlanList() {
      this.planGetListAction({
        employer_id: localStorage.getItem("employer_id"),
        state: 2,
        orderby_type: 1,
      })
        .then((res) => {
          this.planList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    getLog() {
      this.logGetListAction({
        employer_id: localStorage.getItem("employer_id"),
      })
        .then((res) => {
          for (let item of res) {
            item.content = JSON.parse(item.content);
            item.content.create_time = item.create_time;
          }
          this.eventList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    compare(error_id) {
      this.$refs.queueCompareModal.open(error_id);
    },
  },
};
</script>

<style scoped>
.dashboard-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 30px;
  background-color: #f9f9f9;
}
.dashboard-main-container {
  flex: 1;
  display: flex;
  flex-direction: row;
}
.dashboard-left-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 30px;
}
.dashboard-right-container {
  display: flex;
  flex-direction: column;
  min-width: 300px;
}
.dashboard-notenough-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 30px 0;
}
</style>